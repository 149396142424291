.ant-upload {
  &.ant-upload-drag {
    height: auto;
    margin-bottom: 15px;
  }
}

.ant-upload-list {
  margin-bottom: 20px;
}
